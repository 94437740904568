import {
  Props as RichTextEditorProps,
  DEFAULT_INITIAL_VALUE_JSON_STRING,
} from '../../../ui/RichTextEditor'

export const generateKeyResultLinkId = (keyResultId: string): string =>
  `checkin-modal-key-result-${keyResultId}`

export const isDefaultJsonComment = (
  initialValueJSON: RichTextEditorProps['initialValueJSON'],
): boolean => !!(!initialValueJSON || initialValueJSON === DEFAULT_INITIAL_VALUE_JSON_STRING)
